import { CostState } from './cost.types';

export interface IPayment {
  id: number;
  amountPaid: number;
  amountToBePaid: number;
  canPay: boolean;
  cartOrderId?: string;
  cartPaymentId?: string;
  createdAt: string;
  ogm?: string;
  paymentHistory: IPaymentRecord[];
  state: { state: PaymentState; transitions: PaymentState[] };
  type?: { type: PaymentType; name: string };
  reasonForManualEdit?: string;
}

export enum PaymentType {
  online = 'online',
  banktransfer = 'banktransfer',
  salesIntergration = 'ingenico',
}

export interface IPaymentRecord {
  state: PaymentState;
  timestamp: string;
  userName: string;
}

export enum PaymentState {
  new = 'new',
  processing = 'processing',
  processed = 'processed',
  partially_paid = 'partially_paid',
  overpaid = 'overpaid',
  closed = 'closed',
  pending = 'pending',
  declined = 'declined',
  invalid = 'invalid',
  cancelled = 'cancelled',
  accepted = 'accepted',
  uncertain = 'uncertain',
  partialpaid = 'partialpaid',
  timedout = 'timedout',
}

export interface IPaymentPatchState {
  id: string;
  state: PaymentState;
  reasonForManualEdit: string;
  requestId: number;
}

export interface ICostPatchState {
  id: number;
  state: CostState;
  requestId: number;
}

export enum RejectionReason {
  utilityCompanyRequestInvalid = 'utility_company_request_invalid',
}
